import { Injectable, Optional } from '@angular/core';
import { HttpClientService } from '@ohb/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { ISurvey } from '../models/interfaces';
import { SurveysServiceConfig } from './configs/surveys-service-config';

@Injectable({
  providedIn: 'root'
})
export class SurveysService {
  private _apiUrl: string;
  constructor(private http: HttpClientService, @Optional() config: SurveysServiceConfig) {
    if (config) {
      this._apiUrl = config.apiUrl;
    }
  }
  getSurveys(): Observable<any> {
    const request = this.http.get<ISurvey>(this._apiUrl).pipe(
      map(res => {
        return res;
      })
    );
    return request;
  }
}
