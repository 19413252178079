import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'client-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss']
})
export class SurveysComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
