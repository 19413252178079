import { YtPlayerModule } from '@ohb/yt-player';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BackgroundImageComponent } from './components/background-image/background-image.component';
import { VideoCoverComponent } from './components/video-cover/video-cover.component';
import { FeatureLargeComponent } from './components/feature-large/feature-large.component';
import { SmallTextBlockComponent } from './components/small-text-block/small-text-block.component';
import { SwitchableBlockComponent } from './components/switchable-block/switchable-block.component';
import { PftsCommonModule } from '@ohb/common';

@NgModule({
  imports: [CommonModule, YtPlayerModule, RouterModule, PftsCommonModule],
  declarations: [BackgroundImageComponent, VideoCoverComponent, FeatureLargeComponent, SmallTextBlockComponent, SwitchableBlockComponent],
  exports: [BackgroundImageComponent, VideoCoverComponent, FeatureLargeComponent, SmallTextBlockComponent, SwitchableBlockComponent]
})
export class ShareduiModule {}
