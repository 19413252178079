<footer class="footer-6 text-center-xs space--xxs bg--dark">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <img alt="Image" class="logo" src="/assets/images/logo/logo.png" />
        ИП Скуратович Екатерина Ивановна
        <!-- <ul class="list-inline list--hover">
          <li class="list-inline-item">
            <a href="#"> <span class="type--fine-print">Get Started</span> </a>
          </li>
          <li class="list-inline-item">
            <a href="#">
              <span class="type--fine-print">help@stack.io</span>
            </a>
          </li>
        </ul> -->
      </div>
      <div class="col-md-6 text-right text-center-xs">
        <!-- <ul class="social-list list-inline list--hover">
          <li class="list-inline-item">
            <a href="#">
              <i class="socicon socicon-google icon icon--xs"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="#">
              <i class="socicon socicon-twitter icon icon--xs"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="#">
              <i class="socicon socicon-facebook icon icon--xs"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="#">
              <i class="socicon socicon-instagram icon icon--xs"></i>
            </a>
          </li>
        </ul> -->
      </div>
    </div>
    <!--end of row-->
    <div class="row">
      <div class="col-md-4 info">
        <p class="type--fine-print">УНП 19114797</p>
        <p class="type--fine-print">
          Зарегистрирован в Едином государственном реестре 11.01.2013г.
        </p>
        <p class="type--fine-print">Минским райисполкомом.</p>
        <p class="type--fine-print">Республика Беларусь</p>
        <p class="type--fine-print">а.г. Колодищи, ул.Дальняя, д.6</p>
        <p class="type--fine-print">
          <a href="mailto:aptekadushi@gmail.com" class="phone">
            aptekadushi@gmail.com</a
          >
        </p>
        <p class="type--fine-print">
          <a href="tel:+375293461652" class="phone"> +375 29 346 16 52</a>
        </p>
        <!-- <img src="/assets/images/payment/bePaid/08_visa_visasec_mc_mcidcheck_bk_bkip_applepay_gpay_samsungpay_bepaid.svg" /> -->
        <img alt="" class="payLogos" src="/assets/images/payment/bePaid/all-bepaid-logos.svg" />
        <img
          alt="erip" class="eripLogo"
          src="/assets/images/payment/bePaid/erip-footer-transparent-gray.svg"
        />
      </div>
      <div class="col-md-4 info">
        <p class="type--fine-print">
          <a routerLink="payment/price" routerLinkActive="active"> Услуги </a>
        </p>
        <p class="type--fine-print">
          <a routerLink="payment/aferta" routerLinkActive="active">
            Соглашение
          </a>
        </p>
        <p class="type--fine-print">
          <a routerLink="/payment/payments" routerLinkActive="active">
            Способы оплаты
          </a>
        </p>
        <p class="type--fine-print">
          <a routerLink="/payment/return-conditions" routerLinkActive="active">
            Условия возврата
          </a>
        </p>
        <!-- <p class="type--fine-print">
          <a routerLink="/payment/privacy-policy" routerLinkActive="active">
            Политика конфиденциальности
          </a>
        </p> -->
      </div>
      <div class="col-md-4 text-right text-center-xs">
        <span class="type--fine-print"
          >&copy; <span class="update-year"></span> ИП Скуратович Е.И.</span
        >
        <!-- <a class="type--fine-print" href="#">Privacy Policy</a>
        <a class="type--fine-print" href="#">Legal</a> -->
      </div>
    </div>

    <!--end of row-->
  </div>
  <!--end of container-->
</footer>
