import { Component, OnInit } from '@angular/core';
import { SurveysService, ISurvey } from '@ohb/surveys';

@Component({
  selector: 'client-surveys-list',
  templateUrl: './surveys-list.component.html',
  styleUrls: ['./surveys-list.component.scss'],
})
export class SurveysListComponent implements OnInit {
  constructor(private readonly surveyService: SurveysService) {}
  private response: ISurvey;
  public responseStr: string;
  ngOnInit() {
    this.surveyService.getSurveys().subscribe(x => {
      this.response = x;
      this.responseStr = JSON.stringify(this.response);
    });
  }
}
