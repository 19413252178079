import { HttpClient, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {
  private readonly httpClient: HttpClient;

  constructor(private readonly handler: HttpHandler) {
    this.httpClient = new HttpClient(this.handler);
  }

  public get<T>(url: string): Observable<T>;
  // public get<T extends IRequest<T>, R extends IResponse<R>>(
  //   url: string,
  //   req: T
  // ): Observable<R> {
  //   return this.httpClient.get<R>(url, { headers: req.headers });
  // }
  public get(url: string): Observable<any>;
  public get<T>(url: string): Observable<any> {
    return this.httpClient.get<T>(url);
  }
}
