import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SurveysRoutingModule } from './app-surveys-routing.module';
import { SurveysComponent } from './surveys.component';
import { SurveysListComponent } from './components/surveys-list/surveys-list.component';
import { HttpClientModule } from '@ohb/http';
import { SurveysModule } from '@ohb/surveys';

@NgModule({
  declarations: [SurveysComponent, SurveysListComponent],
  imports: [
    CommonModule,
    SurveysRoutingModule,
    HttpClientModule,
    SurveysModule.forRoot({ apiUrl: 'http://localhost:7071/api/surveys' })
  ]
})
export class AppSurveysModule {}
