<!-- <section class="cover height-40 imagebg parallax" data-overlay="4">
  <client-background-image
    [src]="'/assets/images/header.png'"
  ></client-background-image>

</section>

<section class="text-center bg--secondary">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10 col-lg-8">
        <h2>
          Терапевтическая группа длинною в маленькую жизнь или «Лаборатория
          группового процесса»
        </h2>
        <p class="lead">
          Еженедельная терапевтическая группа под очной супервизией групповых
          терапевтов
        </p>
      </div>
    </div>

  </div>

</section>
<section class="switchable bg--secondary">
  <div class="container">
    <div class="row justify-content-around">
      <div class="col-md-6">
        <ul class="accordion accordion--oneopen accordion-2 switchable__text">
          <li class="active">
            <div class="accordion__title">
              <span class="h5">Для кого*</span>
            </div>
            <div class="accordion__content">
              <p class="lead">
                - для тех, кто хочет погрузиться в мир познания граней
                индивидуальности
              </p>
              <p class="lead">
                - для тех, кто хочет исследовать и сформировать свой стиль
                ведущего группы
              </p>
              <p class="lead">
                - для тех, кто хочет научиться понимать динамику группы,
                исследовать ко-терапевтические отношения и научиться
                конструировать групповые эксперименты
              </p>
            </div>
          </li>
          <li>
            <div class="accordion__title">
              <span class="h5">Формы работы*</span>
            </div>
            <div class="accordion__content">
              <ul>
                <li>- групповая терапия в роли участника группы</li>
                <li>
                  - очная супервизия ведущего группы или ко-терапевтической пары
                  ведущих после каждой еженедельной встречи группы
                </li>
                <li>- методический разбор динамики групповых процессов</li>
              </ul>
              <p class="lead">Количество встреч рассчитывается исходя из*</p>
              <p class="lead">
                - количества участников (ограничение-не более 12 человек)
              </p>
              <p class="lead">
                - на каждого участника предполагается три трехчасовые встречи
                терапевтической еженедельной группы в роли ведущего
              </p>
              <p class="lead">
                - три трехчасовые встречи для методологического разбора
                групповых процессов в -микро, -макро и -мегодинамике.
              </p>
            </div>
          </li>
          <li>
            <div class="accordion__title">
              <span class="h5">Время*</span>
            </div>
            <div class="accordion__content">
              <p class="lead">
                Каждый четверг с 14.30-17.30 терапевтическая группа, с
                17.30-18.00 очная супервизия группового терапевта. Начало 11
                апреля 2019 года.
              </p>
            </div>
          </li>
          <li>
            <div class="accordion__title">
              <span class="h5">Стоимость*</span>
            </div>
            <div class="accordion__content">
              <p class="lead">
                30 у.е. одна встреча
              </p>
            </div>
          </li>
          <li>
            <div class="accordion__title">
              <span class="h5">Ведущие проекта и супервизоры*</span>
            </div>
            <div class="accordion__content">
              <p class="lead">
                Олифирович Наталья
              </p>
              <p class="lead">
                Скуратович Екатерина
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-lg-5 col-md-6">
        <img
          alt="Image"
          class="border--round box-shadow-wide"
          src="/assets/images/photo_coffe.jpg"
        />
      </div>
    </div>

  </div>

</section> -->
<!-- <section class="text-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10 col-lg-8">
        <client-video-cover
          [videoId]="'6p45ooZOOPo'"
          src="assets/old/img/hero-1.jpg"
        ></client-video-cover>
      </div>
    </div>
  </div>
</section> -->
<div class="home-root">
  <client-feature-large
    *ngFor="let blog of blogs | async"
    [src]="blog.imageUrl"
    detailsLink="/blogs/{{ blog.id }}"
    [header]="blog.title"
    [body]="blog.synopsis"
  ></client-feature-large>
</div>

<!-- <client-feature-large
  [src]="'assets/old/img/education-3.jpg'"
  [detailsLink]="'/blogs'"
  [bodyTemplate]="bodyTemplate"
>
  <ng-template #bodyTemplate>
    <ul class="accordion accordion--oneopen accordion-2 switchable__text">
      <li class="active">
        <div class="accordion__title">
          <span class="h5">Campus Life</span>
        </div>
        <div class="accordion__content">
          <p class="lead">
            Stack follows the BEM naming convention that focusses on logical
            code readability that is reflected in both the HTML and CSS.
            Interactive elements such as accordions and tabs follow the same
            markup patterns making rapid development easier for developers and
            beginners alike.
          </p>
          <p class="lead">
            Add to this the thoughtfully presented documentation, featuring code
            highlighting, snippets, class customizer explanation and you've got
            yourself one powerful value package.
          </p>
        </div>
      </li>
      <li>
        <div class="accordion__title">
          <span class="h5">Study Support</span>
        </div>
        <div class="accordion__content">
          <p class="lead">
            Stack offers a clean and contemporary to suit a range of purposes
            from corporate, tech startup, marketing site to digital storefront.
            Elements have been designed to showcase content in a diverse yet
            consistent manner.
          </p>
          <p class="lead">
            Multiple font and colour scheme options mean that dramatically
            altering the look of your site is just clicks away &mdash;
            Customizing your site in the included Variant Page Builder makes
            experimenting with styles and content arrangements dead simple.
          </p>
        </div>
      </li>
      <li>
        <div class="accordion__title">
          <span class="h5">Qualification</span>
        </div>
        <div class="accordion__content">
          <p class="lead">
            Medium Rare is an elite author known for offering high-quality,
            high-value products backed by timely and personable support.
            Recognised and awarded by Envato on multiple occasions for producing
            consistently outstanding products, it's no wonder over 20,000
            customers enjoy using Medium Rare templates.
          </p>
        </div>
      </li>
    </ul>
  </ng-template>
</client-feature-large> -->
