import { AppSurveysModule } from './content/apps/surveys/app-surveys.module';
import { CommonModule } from '@angular/common';
import { ContentComponent } from './content/content.component';
import { FooterComponent } from './footer/footer.component';
import { HomeModule } from './content/apps/home/home.module';
import { HttpClientModule } from '@ohb/http';
import { LayoutComponent } from './layout/layout.component';
import { MainRoutingModule } from './main-routing.module';
import { NavbarComponent } from './navbar/navbar.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule, MainRoutingModule, HomeModule, AppSurveysModule, HttpClientModule],
  declarations: [LayoutComponent, FooterComponent, NavbarComponent, ContentComponent],
  exports: [LayoutComponent, HttpClientModule],
})
export class MainModule {}
