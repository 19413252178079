import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'client-feature-large',
  templateUrl: './feature-large.component.html',
  styleUrls: ['./feature-large.component.scss'],
})
export class FeatureLargeComponent implements OnInit {
  @Input() src: string;
  @Input() header: string;
  @Input() body: string;
  @Input() detailsLink: string;
  @Input() headerTemplate: TemplateRef<any>;
  @Input() bodyTemplate: TemplateRef<any>;
  @Input() backgroundClass: string;
  constructor() {}

  ngOnInit() {}

  showHeader(): boolean {
    return !isEmpty(this.header);
  }
  showimage(): boolean {
    return !isEmpty(this.src);
  }
  showBody(): boolean {
    return !isEmpty(this.body);
  }
  showLinkDetails(): boolean {
    return !isEmpty(this.detailsLink);
  }
}
