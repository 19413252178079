import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SurveysService } from './services/surveys.service';
import { SurveysServiceConfig } from './services/configs/surveys-service-config';

@NgModule({
  imports: [
    CommonModule,

    RouterModule.forChild([
      /* {path: '', pathMatch: 'full', component: InsertYourComponentHere} */
    ])
  ],
  providers: [SurveysService],
  exports: []
})
export class SurveysModule {
  // constructor(@Optional() @SkipSelf() parentModule: SurveysModule) {
  //   if (parentModule) {
  //     throw new Error('SurveysModule is already loaded. Import it in the AppModule only');
  //   }
  // }

  static forRoot(config: SurveysServiceConfig): ModuleWithProviders<SurveysModule> {
    return {
      ngModule: SurveysModule,
      providers: [{ provide: SurveysServiceConfig, useValue: config }]
    };
  }
}
