import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Blog } from '../models/blog';
import { HttpClient } from '@angular/common/http';
import { map, catchError, share, filter } from 'rxjs/operators';
import { isNull, isUndefined, sortBy, find } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class BlogsService {
  // private _blog = new BehaviorSubject<Blog>(null);
  private _blogs = new BehaviorSubject<Array<Blog>>(new Array<Blog>());
  public blogs: Observable<Array<Blog>> = this._blogs
    .asObservable()
    .pipe(map((results) => sortBy(results, (blog: Blog) => blog.creationDate)));
  private _blog = new BehaviorSubject<Blog>(null);
  public blog: Observable<Blog> = this._blog
    .asObservable()
    .pipe(filter((value) => !isUndefined(value) && !isNull(value)));
  constructor(protected api: HttpClient) {
    this.getBlogs();
  }
  getBlogs(): Observable<Array<Blog>> {
    const request = this.api.request('GET', 'assets/data/blogs/blogs.json', { responseType: 'json' }).pipe(
      map((res: Array<any>) =>
        res.map((item) => {
          const blog = new Blog(item.id, item.title, item.body);
          if (item.creationDate) {
            blog.creationDate = new Date(item.creationDate);
          }
          if (item.synopsis) {
            blog.synopsis = item.synopsis;
          }
          if (item.imageUrl) {
            blog.imageUrl = item.imageUrl;
          }
          return blog;
        })
      ),
      catchError((err) => {
        return throwError(err);
      }),
      share()
    );

    request.subscribe(
      (blogs) => {
        this._blogs.next(blogs);
      },
      (err) => {
        console.log(err);
        throwError(err);
      }
    );

    return request;
  }
  public get observableBlog(): Observable<Array<Blog>> {
    return this._blogs.asObservable().pipe(filter((item) => !isNull(item)));
  }
  public get blogsList(): Array<Blog> {
    return this._blogs.value;
  }
  setActiveBlog(blogId: string) {
    this.observableBlog.subscribe(() => {
      this._blog.next(find(this._blogs.value, (blog) => blog.id === blogId));
    });
  }
}
