import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';

import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      // {
      //   path: 'blogs',
      //   loadChildren: () => import('./content/apps/blogs/blogs.module').then((m) => m.BlogsModule),
      // },
      // {
      //   path: 'surveys',
      //   loadChildren: () => import('./content/apps/surveys/app-surveys.module').then((m) => m.AppSurveysModule),
      // },
      {
        path: '',
        loadChildren: () => import('@ohb/payment').then((m) => m.PaymentModule),
      },
      // {
      //   path: '',
      //   loadChildren: () => import('./content/apps/home/home.module').then((m) => m.HomeModule),
      // },
    ],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
