import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YtPlayerComponent } from './components/yt-player/yt-player.component';

@NgModule({
  imports: [CommonModule],
  declarations: [YtPlayerComponent],
  exports: [YtPlayerComponent]
})
export class YtPlayerModule {}
