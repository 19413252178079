import { Component, OnInit } from '@angular/core';
import { BlogsService } from '../../../blogs/services/blogs.service';
import { Observable } from 'rxjs';
import { Blog } from '../../../blogs/models/blog';

@Component({
  selector: 'client-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  smallTexts: { title: string; body: string; detailsLink: string }[];
  blogs: Observable<Array<Blog>>;
  constructor (public blogsService: BlogsService) {
    this.blogs = this.blogsService.blogs;
    this.smallTexts = [
      { title: 'string', body: 'string', detailsLink: 'string' },
      { title: 'string', body: 'string', detailsLink: 'string' },
      { title: 'string', body: 'string', detailsLink: 'string' },
      { title: 'string', body: 'string', detailsLink: 'string' },
      { title: 'string', body: 'string', detailsLink: 'string' },
      {
        title: 'Ssg Masters of Psychology',
        body: 'ssg Following BEM conventions for ssg',
        detailsLink: 'string',
      },
    ];
  }

  ngOnInit() {}
}
