<ng-template #defaultHeader>
  <h2 *ngIf="showHeader()">{{ header }}</h2>
</ng-template>
<ng-template #defaultBodyText>
  <p class="lead" *ngIf="showBody()" [innerHtml]="body"></p>
  <a [routerLink]="[detailsLink]" *ngIf="showLinkDetails()"
    >Показать&raquo;</a
  >
</ng-template>
<section class="switchable feature-large {{ backgroundClass }}">
  <div class="container">
    <div class="row justify-content-around">
      <div class="col-md-6">
        <img alt="Image" class="border--round box-shadow-wide" [src]="src" />
      </div>
      <div class="col-md-6 col-lg-5">
        <div class="switchable__text">
          <div class="heading-block">
            <ng-container
              *ngTemplateOutlet="
                headerTemplate ? headerTemplate : defaultHeader
              "
            ></ng-container>
          </div>
          <div class="text-block">
            <ng-container
              *ngTemplateOutlet="bodyTemplate ? bodyTemplate : defaultBodyText"
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
    <!--end of row-->
  </div>
  <!--end of container-->
</section>
