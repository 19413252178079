import * as dayjs from 'dayjs';
export class Blog {
  public synopsis?: string;
  public imageUrl?: string;
  public creationDate: Date = new Date();
  constructor (public id: string, public title: string, public body: string) { }

  public getCreated(): string{
    return dayjs(this.creationDate).format('DD MMM YYYY, H:mm');
  }

  public get isNew(): boolean{
    const newStart = dayjs().subtract(5, 'day');
    return dayjs(this.creationDate).isAfter(newStart);
  }

}
